export default defineNuxtRouteMiddleware((to, from) => {
  const authStore = useAuthStore()
  const orderStore = useOrderStore()
  const device = useDevice()
  const { $toast } = useNuxtApp()

  if (useAppStore().merchant.auth.required && !authStore.isAuthenticated) {
    if (device.isDesktop) {
      authStore.authModal = true
      return navigateTo(to.path == from.path ? '/' : from)
    } else {
      return navigateTo('/login')
    }
  }

  if (!orderStore.canNavigateToCheckout) {
    const message = {
      delivery: 'Пожалуйста, укажите адрес доставки',
      pickup: 'Пожалуйста, выберите точку самовывоза',
      dinein: 'Пожалуйста, выберите заведение',
    }[orderStore.form.orderTypeCode]

    $toast({
      type: 'error',
      title: message,
      description: 'Чтобы перейти к оформлению заказа',
    })

    if (device.isDesktop) {
      orderStore.addressModal = true
      return navigateTo('/')
    } else {
      return navigateTo('/address')
    }
  }
})
